import {
  PerspectiveCamera,
  Scene,
  Mesh,
  WebGLRenderer,
  TextGeometry,
  FontLoader,
  MeshLambertMaterial,
  Color,
  Vector3,
  DirectionalLight,
  Box3,
  Group,
} from 'three';
import $ from 'jquery';

let camera, cameraTarget, scene, renderer;
let text, textPivot;

const mouse = {
  x: 0,
  y: 0
}
 
init();
animate();
 
function init() {
    camera = new PerspectiveCamera(90, window.innerWidth / window.innerHeight, 1, 1500);
    camera.position.set(0, 0, 0);

    cameraTarget = new Vector3(0, 0, 0);
 
    scene = new Scene();
    scene.background = new Color( 0x144037 );

    const loader = new FontLoader();
    loader.load(
      'fonts/lilita_one_regular.json',
      response => {
        addText(response, 'T');
      }
    );

    const dirLight = new DirectionalLight( 0xffffff, 1 );
    dirLight.position.set( 0, 0, 400 ).normalize();
    scene.add(dirLight);
 
    renderer = new WebGLRenderer( { antialias: true } );
    renderer.setSize( window.innerWidth, window.innerHeight );
    $('#canvas-container').append(renderer.domElement);
}

function addText(font, val) {
  const geometry = new TextGeometry( val, {
    font: font,
    size: 100,
    height: 5,
    curveSegments: 15,
		bevelEnabled: true,
		bevelThickness: 10,
		bevelSize: 2,
		bevelOffset: 0,
		bevelSegments: 10
  } );
  const material = new MeshLambertMaterial( { color: 0xf0ebd8 } );

  text = new Mesh( geometry, material );
  
  textPivot = new Group();
  textPivot.position.set(-30,-50,-500);

  scene.add(textPivot);
  textPivot.add(text);
}

function rotate(target, x, y) {
  target.rotation.x = x;
  target.rotation.y = y;
}
 
function animate() {
  requestAnimationFrame( animate );
  render();
}

let tempX = 0;
let tempY = 0;

function render() {
  camera.lookAt( cameraTarget );

  if (textPivot && !window.matchMedia('(min-width: 768px)').matches) {
    rotate(textPivot, Math.sin(tempX) / 4, Math.cos(tempY) / 4);

    tempX += 0.05;
    tempY += 0.05;
  }

  renderer.clear();
  renderer.render( scene, camera );
}

window.addEventListener( 'resize', onWindowResize, false );

function onWindowResize(){
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
}

window.addEventListener('mousemove', e => {
  if (textPivot && window.matchMedia('(min-width: 768px)').matches) {
    mouse.x = e.clientX - window.innerWidth / 2;
    mouse.y = e.clientY - window.innerHeight / 2;
  
    rotate(textPivot, mouse.y / 1000, mouse.x / 1000);
  }
})